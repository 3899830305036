import { createAction, props } from '@ngrx/store';
import { AvailableCustomer, User } from '@app/auth/store/auth.interface';

export const login = createAction(
  '[Auth] User Login',
  props<{ email, password }>()
);

export const loginSso = createAction(
  '[Auth] User Login SSO',
  props<{ id_token }>()
);

export const loginSuccess = createAction(
  '[Auth] User Login success',
  props<{ user: User }>()
);

export const loginSuccessRedirect = createAction(
  '[Auth] User Login success redirect'
);

export const logout = createAction(
  '[Auth] User Logout'
);

export const forceLogout = createAction(
  '[Auth] User Force Logout'
);

export const loginFailed = createAction(
  '[Auth] Login Failed',
  props<{ error: any }>()
);

export const loginRedirect = createAction(
  '[Auth] Login Redirect'
);

export const desktopRedirect = createAction(
  '[Desktop] Desktop Redirect',
  props<{ route: string }>()
);

export const setlocalStorageAfterLogin = createAction(
  '[Auth] Set Local Storage',
  props<{user: User}>()
);

export const setDemoIncludedModules = createAction(
  '[Auth] Set demo included modules',
  props<{ demo_included_modules: number[] }>()
);

export const includeDemoModule = createAction(
  '[Auth] Include demo module',
  props<{ id: number }>()
);

export const excludeDemoModule = createAction(
  '[Auth] Exclude demo module',
  props<{ id: number }>()
);

export const createEditedArCes = createAction(
  '[Auth] Create edited AR CES'
);

export const createEditedArCesModule = createAction(
  '[Auth] Create edited AR CES module',
  props<{ key: string, value: string }>()
);

export const removeEditedArCesModule = createAction(
  '[Auth] Remove edited AR CES module',
  props<{ key: string }>()
);


export const createEditedCabElements = createAction(
  '[Auth] Create edited Cab elements'
);

export const setEditedCabElements = createAction(
  '[Auth] Set edited Cab elements',
  props<{ key: string, value: string }>()
);

export const removeEditedCabElements = createAction(
  '[Auth] Remove edited Cab elements',
  props<{ key: string }>()
);

export const switchCustomer = createAction(
  '[Auth] Switch Customer',
  props<{ customer_id: number, available_customers: AvailableCustomer[], user_id: number }>()
)

export const authRequestFailed = createAction(
  '[Auth] Request Failed',
  props<{ error: any }>()
);
