import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '@shared/services/user/user.service';
import { FormAction } from '@shared/interfaces/FormAction';
import { SnackBarComponent } from '@shared/components/snack-bar/snack-bar.component';
import { loginSuccess } from '@app/auth/store/auth.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-tfa',
  templateUrl: '../form.html',
  styleUrls: ['../form.scss']
})
export class TfaComponent {

  public form: UntypedFormGroup;
  public exclude: string[];
  public controls: any[];
  public massages: any[];
  public pageTitle: string;
  public formActionBtns: FormAction[];
  public submitted: boolean;
  public numberPatternValidator = Validators.pattern('^[0-9]*$');
  public pageType: string = '2fa';
  public errorText: string = '';

  constructor(private fb: UntypedFormBuilder,
              private router: Router,
              private store: Store,
              private userService: UserService,
              private snackBar: SnackBarComponent) {
    this.form = this.fb.group({

      tfaCode: this.fb.control('', [Validators.required, this.numberPatternValidator]),
      persist: this.fb.control(false)
    });
    this.pageTitle = 'Verification';
    this.exclude = ['persist'];
    this.massages = [
      {
        title: 'We just sent to your phone a verification code.',
        text: 'Please wait a couple of minutes and if it\'s not received - try to login again in order to request a new verification code.'


      }
    ];
    this.formActionBtns = [
      {
        type: 'a',
        class: 'forgot-pass',
        label: 'Resend verification code',
        action: () => {
          this.userService.resendMfa().subscribe((res) => {
            this.snackBar.open('New verification code has been sent. Delivery of SMS/Email might take a couple of minutes');
          });
        }
      },
      {type: 'button', class: 'tfa-submit-btn', label: 'Continue', action: ''}
    ];
  }

  send() {
    if (this.form.valid) {
      this.userService.twoFactorsAuthentication(this.form.controls['tfaCode'].value, this.form.controls['persist'].value)
        .subscribe(res => {
          const deepCopyData = JSON.parse(JSON.stringify(res));
          this.store.dispatch(loginSuccess({user: deepCopyData }));
          if (this.userService.returnUrl) {
            this.router.navigateByUrl(this.userService.returnUrl);
          } else {
            this.router.navigate(['/']);
          }
        });
    }
  }

}

