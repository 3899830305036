import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserService } from '@shared/services/user/user.service';

@Injectable({
  providedIn: 'root',
})
export class SelectivePreloadingStrategyService implements PreloadingStrategy {

  constructor(private userService: UserService) {

  }

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    if (route.data && route.data['preload'] && !route.data['permission']) {
      return load();
    }

    if (route.data && route.data['preload'] && route.data['permission']) {
      if (this.userService.userData && this.userService.userData.selected_customer.permissions.indexOf(route.data['permission']) !== -1) {
        return load();
      }
    }

    return of(null);
  }
}
