import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserService } from '@shared/services/user/user.service';
import { SnackBarComponent } from '@shared/components/snack-bar/snack-bar.component';

@Injectable({
  providedIn: 'root'
})
export class DesktopResolverService  {

  constructor(private userService: UserService,
              private router: Router,
              private snackBar: SnackBarComponent ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const permissions = this.userService.userData.selected_customer.permissions;

    if (state.url === '/') {

      if (this.userService.userData.selected_customer.permissions.includes('dashboard_can_get_new_dashboard')) {
        this.router.navigate(['dashboard'])
      } else if (this.userService.desktopPages.length !== 0 && permissions.includes('dashboard_can_get_reports')) {
        this.router.navigate(['report']);
      } else if (permissions.includes('dashboard_can_get_campaigns')) {
        this.router.navigate(['campaigns']);
      } else if (permissions.includes('dashboard_can_get_cab')) {
        this.router.navigate(['cab']);
      }

    }

    // redirect to campaigns page when try to navigate to report while no reports
    if (this.userService.desktopPages.length === 0 && state.url.includes('report/')) {
      if (permissions.includes('dashboard_can_get_campaigns')) {
        this.router.navigate(['campaigns']);
      }
    }

    // redirect to first report page when navigating to reports root route
    if (state.url === '/report' && permissions.includes('dashboard_can_get_reports')) {
      if (this.userService.desktopPages.length > 0) {
        this.router.navigate(['report', this.userService.desktopPages[0].route]);
      } else {
        this.snackBar.open('Error getting your report');
      }

    }

    return {};
  }

}

