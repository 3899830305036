import { Component, OnInit } from '@angular/core';

import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-mobile',
  template: `
      <div class="app-mobile"></div>
  `,
  styleUrls: ['./mobile.component.scss'],
})
export class MobileComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { }
}
