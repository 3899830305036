import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import {UserService} from '@shared/services/user/user.service';

@Injectable()
export class AppGuard  {
  constructor(private router: Router,
              private userService: UserService) { }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): boolean {
    const customer: any = this.userService.userData;
    if (!customer || !customer['name']) {
      if (state.url !== '/') {
        this.userService.returnUrl = state.url;
      }
      this.router.navigate(['/login']);
      return false;
    } else {
      return true;
    }
  }
}
