import {Injectable, OnInit} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService implements OnInit {

  busy = new BehaviorSubject<boolean>(false);
  private requestsInProgress = 0;

  constructor() {}

  ngOnInit() {
  }

  pushRequest() {
    this.requestsInProgress++;
    this.busy.next(this.requestsInProgress > 0);
  }

  popRequest() {
    this.requestsInProgress--;
    this.busy.next(this.requestsInProgress > 0);
  }
}
