import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from './auth.reducer';
import { User } from '@app/auth/store/auth.interface';

const selectAuthState = createFeatureSelector<AuthState>('auth');

export const selectUser = createSelector(
  selectAuthState,
  (auth: AuthState) => auth?.user
);

export const selectCpId = createSelector(
  selectAuthState,
  (auth: AuthState) => auth?.user?.selected_customer.cp_id
);

export const isLoggedIn = createSelector(
  selectAuthState,
  (auth: AuthState) => !!auth.user
);

export const selectCustomerPermissions = createSelector(
  selectAuthState,
  (auth: AuthState) => auth.user.selected_customer.permissions
);

export const selectUserAndPermissions = createSelector(
  selectAuthState,
  selectCustomerPermissions,
  selectUser,
  (auth: AuthState, permissions: string[], user: User) => [permissions, user]
);

export const selectEditedArCes = createSelector(
  selectAuthState,
  (auth: AuthState) => auth.user.edited_ar_ces
);
