import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class MobileGuard  {
  constructor(private router: Router,
              @Inject(PLATFORM_ID) private platformId: Object) { }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): boolean {
    const isMobile = isPlatformBrowser(this.platformId) && window.innerWidth <= 600;
    if (isMobile) {
      this.router.navigate(['/', 'mobile']);
      return false;
    }
    return true;
  }
}
