import 'rxjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';

let domain = '';
if (window.location.href.includes(environment.demoDomain)) {
  domain = environment.demoDomain;
} else {
  domain = environment.localnonDemoDomain || environment.nonDemoDomain;
}

const redirectSignIn = `${domain}/${environment.redirectSignInSuffix}`;
const redirectSignOut = `${domain}/${environment.redirectSignOutSuffix}`;

Amplify.configure({
  ...awsconfig,
  oauth: {
    ...awsconfig.oauth,
    domain: environment.authDomain,
    redirectSignIn,
    redirectSignOut
  }
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
