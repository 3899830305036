import { TfaComponent } from './tfa/tfa.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthComponent} from './auth.component';
import {LoginComponent} from './login/login.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {InputTypePipe} from './pipes/input-type.pipe';
import {CamelToSpacesPipe} from './pipes/camel-to-spaces.pipe';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import {RouterModule} from '@angular/router';
import {UiSharedModule} from '@shared/ui-shared.module';
import { InputLabelPipe } from './pipes/input-label.pipe';
import { SetPasswordComponent } from './set-password/set-password.component';
import {ChangePasswordComponent} from './change-password/change-password.component';
import { LoginssoComponent } from './loginsso/loginsso.component';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {authFeatureKey, authReducer} from '@app/auth/store/auth.reducer';
import {AuthEffects} from '@app/auth/store/auth.effects';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [
    AuthComponent,
    LoginComponent,
    ResetPasswordComponent,
    InputTypePipe,
    CamelToSpacesPipe,
    TfaComponent,
    InputLabelPipe,
    SetPasswordComponent,
    ChangePasswordComponent,
    LoginssoComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    UiSharedModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSnackBarModule,
    StoreModule.forFeature(authFeatureKey, authReducer),
    EffectsModule.forFeature([AuthEffects])
  ],
})
export class AuthModule {
}
