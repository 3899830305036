import { createReducer, on } from '@ngrx/store';
import { AuthActions } from './action-types';
import { DevicePages, User } from '@app/auth/store/auth.interface';
import { createEditedArCes, removeEditedArCesModule } from '@app/auth/store/auth.actions';


export const authFeatureKey = 'auth';

export interface AuthState {
  user?: User;
}

export const initialAuthState: AuthState = {
  user: undefined
};

export const authReducer = createReducer(
  initialAuthState,
  on(AuthActions.loginSuccess, (state: AuthState, { user }) => {
    const updatedUser = {...user, id_token: user.cognito ? user.id_token : null };
    const pages: DevicePages = calculatePages(user);
    return {...state, user: {...updatedUser, ...pages }};
  }),

  on(AuthActions.loginSso, (state: AuthState, { id_token }) => {
    return {...state, user: { ...state.user, id_token } };
  }),

  on(AuthActions.createEditedCabElements, (state: AuthState) => {
    const updatedState = JSON.parse(JSON.stringify(state));
    updatedState.user.selected_customer.edited_cab_elements = {};
    return updatedState;
  }),

  on(AuthActions.setEditedCabElements, (state: AuthState, { key, value }) => {
    const updatedState = JSON.parse(JSON.stringify(state));
    updatedState.user.selected_customer.edited_cab_elements[key] = value;
    return updatedState;
  }),

  on(AuthActions.removeEditedCabElements, (state: AuthState, { key }) => {
    const updatedState = JSON.parse(JSON.stringify(state));
    if (updatedState.user.selected_customer.edited_cab_elements.hasOwnProperty(key)) {
      delete updatedState.user.selected_customer.edited_cab_elements[key];
    }
    return updatedState;
  }),

  on(AuthActions.logout, (state: AuthState) => ({ user: undefined })),

  on(AuthActions.setDemoIncludedModules, (state: AuthState, { demo_included_modules }) => {
    return {
      ...state, user: {
        ...state.user,
        selected_customer: {
          ...state.user.selected_customer,
          demo_included_modules
        }
      }
    };
  }),

  on(AuthActions.includeDemoModule, (state: AuthState, { id } ) => {
    const updatedState = JSON.parse(JSON.stringify(state));
    const demoModulesPresent = updatedState.user.selected_customer.hasOwnProperty('demo_included_modules');
    if (demoModulesPresent) {
      updatedState.user.selected_customer.demo_included_modules.push(id);
    } else {
      updatedState.user.selected_customer.demo_included_modules = [];
      updatedState.user.selected_customer.demo_included_modules.push(id);
    }
    return updatedState;
  }),

  on(AuthActions.excludeDemoModule, (state: AuthState, { id }) => {
    const updatedState = JSON.parse(JSON.stringify(state));
    const demoModulesPresent = updatedState.user.selected_customer.hasOwnProperty('demo_included_modules');
    if (demoModulesPresent) {
      updatedState.user.selected_customer.demo_included_modules =
        updatedState.user.selected_customer.demo_included_modules.filter(e => e.id !== id);
    } else {
      updatedState.user.selected_customer.demo_included_modules = [];
    }
    return updatedState;
  }),

  on(AuthActions.createEditedArCes, (state: AuthState) => {
    const updatedState = JSON.parse(JSON.stringify(state));
    updatedState.user.edited_ar_ces = {};
    return updatedState;
  }),

  on(AuthActions.createEditedArCesModule, (state: AuthState, { key, value }) => {
    const updatedState = JSON.parse(JSON.stringify(state));
    updatedState.user.edited_ar_ces[key] = value;
    return updatedState;
  }),

  on(AuthActions.removeEditedArCesModule, (state: AuthState, { key }) => {
    const updatedState = JSON.parse(JSON.stringify(state));
    if (updatedState.user.edited_ar_ces.hasOwnProperty(key)) {
      delete updatedState.user.edited_ar_ces[key];
    }
    return updatedState;
  }),
);

function calculatePages(user: User): DevicePages {
  if (!user || !user.selected_customer) {
    return { desktopPages: null };
  }
  const data = JSON.parse(JSON.stringify(user));
  const pages = data.selected_customer.report_pages.filter(p => !p.displayName.toLowerCase().includes('mobile'));

  pages.forEach(p => {
    const value = p.displayName.split(' ').join('-');
    p.route = `/report/${value}`;
    p.childRoute = value;
  });
  return { desktopPages: pages };
}

