import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { SendEmailDialogComponent } from '../components/send-email-dialog/send-email-dialog.component';

@Injectable()
export class SendEmailDialogService {
  constructor(private dialog: MatDialog) { }
  dialogRef: MatDialogRef<SendEmailDialogComponent>;

  public open(options) {
    const defaultTitle = 'Send Test';
    const defaultMessage = '<span class="highlight">*</span> Before sending a test please complete the on-boarding and whitelisting\n' +
      '<a href="https://support.cybeready.com/hc/en-us/articles/360020100559-CybeReady-onboarding-guide" target="_blank">process</a>.';
    const defaultCancelText = 'Cancel';
    const defaultConfirmText = 'Send Test';
    this.dialogRef = this.dialog.open(SendEmailDialogComponent, {
      width: '50%',
      restoreFocus: false,
      data: {
        title: options.title ? options.title : defaultTitle,
        message: options.message ? options.message : defaultMessage,
        cancelText: options.cancelText ? options.cancelText : defaultCancelText,
        confirmText: options.confirmText ? options.confirmText : defaultConfirmText,
        removable: options.removable ? options.removable : true,
        chips: options.chips,
        defaultLocales: options.defaultLocales,
        locales: options.locales,
        currentLocale: options.currentLocale,
        addEmailPermission: options.addEmailPermission ? options.addEmailPermission : true,
      }
    });
  }

  public confirmed(): Observable<any> {
    return this.dialogRef.afterClosed().pipe(take(1), map(res => {
      return res;
    }
    ));
  }
}
