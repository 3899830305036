import {Component, Injectable, OnInit} from '@angular/core';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarConfig as MatSnackBarConfig } from '@angular/material/legacy-snack-bar';

@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss']
})
export class SnackBarComponent implements OnInit {
  public config: MatSnackBarConfig = {
    'duration': 5000,
    'horizontalPosition': 'right',
    'verticalPosition': 'top',
    'politeness': 'assertive',
  };
  public action = 'Got it';

  constructor(
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
  }

  open(message: string, config?) {
    if (config && config.action) {
      this.action = config.action;
      delete config.action;
    }
    Object.assign(this.config, config);
    this.snackBar.open(message, this.action, this.config);
  }
}
