import {Injectable} from '@angular/core';
import {UiHttp} from '@shared/services/ui-http/ui-http.service';
import {Observable} from 'rxjs';
import {Credentials} from '@app/auth/store/auth.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: UiHttp) {
  }

  login(credentials: Credentials): Observable<any> {
    return this.http.post('get-api-token/', credentials);
  }

  loginSso(id_token: string): Observable<any> {
    return this.http.post('sso-login/', id_token);
  }

  logout(): Observable<any> {
    return this.http.get('logout/');
  }
}
