import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { UserService } from '@shared/services/user/user.service';
import { FormAction } from '@shared/interfaces/FormAction';
import { SnackBarComponent } from '@shared/components/snack-bar/snack-bar.component';

@Component({
  selector: 'app-set-password',
  templateUrl: '../form.html',
  styleUrls: ['../form.scss']
})
export class SetPasswordComponent implements OnInit {

  public form: UntypedFormGroup;
  public exclude: string[];
  public pageTitle: string;
  public formActionBtns: FormAction[];
  public submitted: boolean;
  public pageType: string = 'set-password';
  public errorText: string = '';

  constructor(private fb: UntypedFormBuilder,
              private router: Router,
              private userService: UserService,
              private route: ActivatedRoute,
              private snackBar: SnackBarComponent) {
    this.pageTitle = 'Set Password';
    this.exclude = ['persist'];
    const password = this.fb.control('', [Validators.required, Validators.minLength(8)]);
    this.form = this.fb.group({
      password,
      validatePassword: this.fb.control('', [Validators.required, Validators.minLength(8)])
    }, { validator: this.passwordMatchValidator });

    this.exclude = [];
    this.formActionBtns = [
      {
        type: 'button',
        class: 'login-submit-btn',
        label: 'Set Password',
        action: ''
      }

    ];
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.userService.validateActivationLink(params['token']).subscribe();
    });
  }

  send() {
    if (this.form.valid) {
      this.userService.setPassword(this.form.value['password']).subscribe(() => {
        this.router.navigate(['/', 'login']);
      });
    } else {
      this.snackBar.open('Passwords do not match');
    }
  }

  passwordMatchValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const password = control.get('password');
    const confirmPassword = control.get('validatePassword');

    if (!password || !confirmPassword) {
      return null;
    }

    return password.value === confirmPassword.value ? null : { 'passwordMismatch': true };
  }

}
