import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '@shared/services/user/user.service';
import {FormAction} from '@shared/interfaces/FormAction';
import { SnackBarComponent } from '@shared/components/snack-bar/snack-bar.component';
import { MatLegacySnackBarConfig as MatSnackBarConfig } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-reset-password',
  templateUrl: '../form.html',
  styleUrls: ['../form.scss']
})
export class ResetPasswordComponent implements OnInit {

  public form: UntypedFormGroup;
  public exclude: string[];
  public pageTitle: string;
  public formActionBtns: FormAction[];
  public submitted: boolean;
  public pageType: string = 'reset-password';
  public errorText: string = '';
  private snackBarConfig: MatSnackBarConfig = { panelClass: 'login-snackbar' };

  constructor(private fb: UntypedFormBuilder,
              private router: Router,
              private snackBar: SnackBarComponent,
              private userService: UserService,
              private route: ActivatedRoute) {
    this.pageTitle = 'Reset Passsword';
    this.exclude = ['persist'];
    this.form = this.fb.group({
      email: this.fb.control('', [Validators.required, Validators.email])
    });

    this.exclude = [];
    this.formActionBtns = [
      {
        type: 'a',
        class: 'forgot-pass',
        label: 'Back to Login?',
        action: () => {
          this.router.navigate(['/', 'login']);
        }
      },
        {
      type: 'button', class: 'login-submit-btn', label: 'Get Reset link!', action: ''}

    ];
  }

  ngOnInit() {
  }

  send() {
    if (this.form.valid) {
      this.userService.resetPassword(this.form.value['email']).subscribe(() => {
        this.router.navigate(['/', 'login']);
        this.snackBar.open('An email with a link to reset password has been sent, check your inbox', this.snackBarConfig );
      },
        (error => {
          this.snackBar.open(error.error['non_field_errors'][0], this.snackBarConfig);
        }));
    }
  }

}
