import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CampaignsService} from '@desktop/campaigns/campaigns.service';
import { SnackBarComponent } from '@shared/components/snack-bar/snack-bar.component';

@Component({
  selector: 'app-send-email-dialog',
  templateUrl: './send-email-dialog.component.html',
  styleUrls: ['./send-email-dialog.component.scss']
})

export class SendEmailDialogComponent implements OnInit {
  emailCtrl = new UntypedFormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      chips: Array<string>,
      removable: boolean | true,
      cancelText: string | null,
      confirmText: string | null,
      message: string | null,
      title: string | null,
      addEmailPermission: boolean | true;
    },
    private dialogRef: MatDialogRef<SendEmailDialogComponent>,
    private campaignsService: CampaignsService,
    private snackBar: SnackBarComponent,
  ) { }

  ngOnInit(): void {
  }

  public cancel() {
    this.close(false);
  }

  public close(value) {
      this.dialogRef.close(value);
  }

  public confirm() {
      this.close(this.data);
  }

  remove(chip: string): void {
    const index = this.data.chips.indexOf(chip);

    if (index >= 0) {
      this.data.chips.splice(index, 1);
    }
  }

  @HostListener('keydown.esc')
  public onEsc() {
    this.close(false);
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if (this.campaignsService.validateEmail(value)) {
      this.data.chips.push(value.trim());
    } else {
      this.snackBar.open('Please enter valid email address');
    }

    if (input) {
      input.value = '';
    }

    this.emailCtrl.setValue(null);
  }

  paste(event: ClipboardEvent): void {
    event.preventDefault();
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    event.clipboardData
    .getData('Text').trim()
    .split(/;|:|,|\n/)
    .forEach(value => {
      value = value.trim().replace('[', '').replace(']', '');
      const contact = value.match(/<([^']+)>/);
      if (contact) {
        this.data.chips.push(contact[1]);
      } else if (re.test(value)) {
        this.data.chips.push(value);
      }
    });
  }
}
