import {Action, ActionReducer, ActionReducerMap, MetaReducer} from '@ngrx/store';
import { environment } from '../../environments/environment';
import {SettingsState} from '../desktop/settings/store/settings.reducer';
import {AuthState} from '@app/auth/store/auth.reducer';

export interface AppState {
  settings?: SettingsState;
  auth?: AuthState;
}

export const reducers: ActionReducerMap<AppState> = {

};
export const initialAppState: AppState = {
};

export function clearStateMetaReducer<State extends {}>(reducer: ActionReducer<State>): ActionReducer<State> {
  return function clearStateFn(state: State, action: Action) {
    if (['[Auth] User Logout', '[Auth] Switch Customer', '[Auth] User Force Logout'].includes(action.type)) {
      state = {} as State;
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<AppState>[] =
  !environment.production ? [clearStateMetaReducer] : [clearStateMetaReducer];
