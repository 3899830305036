import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { LoadingService } from '@shared/services/ui-http/loading.service';
import { UserService } from '@shared/services/user/user.service';
import { NavigationEnd } from '@angular/router';
import { environment } from '@env/environment';
import { AuthActions } from '@app/auth/store/action-types';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-root',
  template: `
      <router-outlet></router-outlet>
      <div id="pbi-el"></div>
      <div *ngIf="busy" class="spinner-wrap">
          <span><mat-spinner class="progress-spinner"></mat-spinner></span>
      </div>
  `,
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private loadingService: LoadingService,
              private userService: UserService,
              private store: Store,
              private router: Router) {
    const user = localStorage.getItem('customer');
    if (user) {
      const data = JSON.parse(user);
      const deepCopyData = JSON.parse(JSON.stringify(data));
      this.store.dispatch(AuthActions.loginSuccess(({user: deepCopyData})));
    }
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        (<any>window).ga('set', 'page', event.urlAfterRedirects);
        (<any>window).ga('send', 'pageview');
      }
    });
  }
  public busy = false;

  ngOnInit() {
    this.loadingService.busy.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(val => {
      this.busy = val;
    });

    this.userService.preloadPbi();
    (<any>window).ga('create', environment.gaCode, 'auto');
  }
}
