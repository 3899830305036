import { Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { isLoggedIn } from '@app/auth/store/auth.selectors';
import { AuthActions } from '@app/auth/store/action-types';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard  {
  constructor(private store: Store) {}

  canActivate(): Observable<boolean> {
    return this.store.select(isLoggedIn).pipe(
      map((loggedIn) => {
        if (loggedIn) {
          this.store.dispatch(AuthActions.loginSuccessRedirect());
          return false;
        }
        return true;
      }),
      take(1)
    );
  }
}
