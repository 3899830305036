import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ContentEditDialogComponent } from '../components/content-edit-dialog/content-edit-dialog.component';


@Injectable()
export class ContentEditDialogService {
  constructor(private dialog: MatDialog) { }
  dialogRef: MatDialogRef<ContentEditDialogComponent>;

  public open(options) {

    this.dialogRef = this.dialog.open(ContentEditDialogComponent, {
      width: '50%',
      restoreFocus: false,
      data: {
       defaultText: options.defaultText,
       customText: options.customText,
       textType: options.textType,
       rtl: options.rtl,
       maxLength: options.maxLength || 250,
       customTitle: options.customTitle,
      }
    });
  }

  public confirmed(): Observable<any> {
    return this.dialogRef.afterClosed().pipe(take(1), map(res => {
        return res;
      }
    ));
  }

  parseLoginName(loginName: string, sender: string): string[] {
    const loginNameEnding = sender.split('<')[1].split('@')[1].split('>')[0];
    return [(loginName), ('@' + loginNameEnding)];
  }

  public validateData(data: string): boolean {
    const regex = /<[^>]*>/;
    const isValid = !regex.test(data);
    return isValid;
  }
}
