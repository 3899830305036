import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, mergeMap, tap } from 'rxjs/operators';
import { AuthActions } from './action-types';
import { Router } from '@angular/router';
import { UiHttp } from '@shared/services/ui-http/ui-http.service';
import { AuthService } from '@app/auth/auth.service';
import { Credentials, User } from '@app/auth/store/auth.interface';
import { of } from 'rxjs';
import { loginFailed, loginSuccessRedirect, setlocalStorageAfterLogin } from '@app/auth/store/auth.actions';
import { UserService } from '@shared/services/user/user.service';
import { internalUsersDomain } from '@app/auth/constants';


@Injectable()
export class AuthEffects {

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      exhaustMap((credentials: Credentials) =>
        this.authService.login(credentials).pipe(
          mergeMap((user) => [
            AuthActions.setlocalStorageAfterLogin({ user }),
            AuthActions.loginSuccess({ user }),
            AuthActions.loginSuccessRedirect()
          ]),
          catchError((error) => of(AuthActions.loginFailed({ error })))
        )
      )
    )
  );

  loginSso$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginSso),
      exhaustMap(sso =>
        this.authService.loginSso(sso.id_token).pipe(
          map((user) => AuthActions.loginSuccess({ user })),
          map(() => AuthActions.loginSuccessRedirect()),
          catchError((error) => of(AuthActions.loginFailed({ error })))
        )
      )
    )
  );

  loginSuccessRedirect$ = createEffect(() =>
      this.actions$.pipe(
        ofType(AuthActions.loginSuccessRedirect),
        map((action) => action),
        tap(() => {
          if (this.userService.returnUrl) {
            this.router.navigateByUrl(this.userService.returnUrl).then(() => this.userService.returnUrl = undefined);
          } else {
            this.router.navigate(['/']);
          }
        }
      )),
    { dispatch: false }
  );

  loginRedirect$ = createEffect(() =>
      this.actions$.pipe(
        ofType(AuthActions.loginRedirect, AuthActions.logout),
        tap(() => {
          this.router.navigate(['/login']);
        })
      ),
    { dispatch: false }
  );

  guardRedirect$ = createEffect(() =>
      this.actions$.pipe(
        ofType(AuthActions.desktopRedirect),
        map(action => action.route),
        tap((route: string) => {
          this.router.navigate([route]);
        })
      ),
    { dispatch: false }
  );

  logout$ = createEffect(() =>
      this.actions$.pipe(
        ofType(AuthActions.logout),
        exhaustMap(() =>
          this.authService.logout().pipe(
            // tap(() => this.router.navigate(['/login'])),
            catchError((error) => of(AuthActions.authRequestFailed({ error })))
          )
        )
      )
  );

  setlocalStorageAfterLogin$ = createEffect(() =>
      this.actions$.pipe(
        ofType(AuthActions.setlocalStorageAfterLogin),
        map(action => action.user),
        tap((user: User) => {
          localStorage.setItem('customer', JSON.stringify(user));
        })
      ),
    { dispatch: false }
  );

  switchCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.switchCustomer),
      exhaustMap((action) => {
          return this.userService.switchCustomer(action.customer_id).pipe(
            map((user: any) => {
              delete user.id;
              const updated_user = {...user, demo: user.selected_customer.demo, logo: user.selected_customer.logo,
                available_customers: action.available_customers, user_id: action.user_id};
              this.userService.userData = {...updated_user};
              if (this.router.url.startsWith('/campaigns/')) {
                this.router.navigate(['/campaigns']).then(() => window.location.reload());
              } else {
                this.router.navigate(['/']).then(() => window.location.reload());
              }
              return AuthActions.loginSuccess({user: updated_user});
            }),
            catchError((error: any) =>
              of(AuthActions.authRequestFailed(error))
            )
          );
        }
      )
    )
  );

  churnZeroSetActiveContact$ = createEffect(() =>
      this.actions$.pipe(
        ofType(AuthActions.loginSuccess),
        map(action => action.user),
        tap((user: User) => {
          if (!user.email.endsWith(internalUsersDomain)) {
            (<any>window).ChurnZero = (<any>window).ChurnZero || [];
            (<any>window).ChurnZero.push(['setContact', user.selected_customer.hubspot_profile?.id, user.email]);
          }
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions,
              private http: UiHttp,
              private authService: AuthService,
              private userService: UserService,
              private router: Router) {
  }
}
