import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';


@Injectable()
export class UiHttp {

  private base_url: string;

  constructor(public http: HttpClient) {
    this.base_url = environment.api;
  }

  setBaseUrl(path: string) {
    this.base_url = path;
  }

  get(method: string, payload: any = {}, asFile = false): Observable<any> {
    let params: HttpParams = new HttpParams();
    for (const key in payload) {
      params = params.set(key, payload[key]);
    }
    if (asFile) {
      return this.http.get(`${this.base_url}/${method}`, {
        params: params,
        responseType: 'blob',
        observe: 'response',
      });
    }

    return this.http.get(`${this.base_url}/${method}`, {
      params: params
    });
  }

  post(method: string, payload: any = {}, useMap: boolean = true): Observable<any> {
    return this.http.post(`${this.base_url}/${method}`, payload);
  }

  put(method: string, payload: any = {}): Observable<any> {
    return this.http.put(`${this.base_url}/${method}`, payload);
  }

  patch(method: string, payload: any = {}): Observable<any> {
    return this.http.patch(`${this.base_url}/${method}`, payload);
  }

  delete(method: string, payload: any = {}): Observable<any> {
    return this.http.delete(`${this.base_url}/${method}`, payload);
  }
}
