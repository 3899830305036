/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_RxfheICAm",
    "aws_user_pools_web_client_id": "q6pu404fr99eds1snmq99a43e",
    "oauth": {
        "domain": "dashboard06b79884-06b79884-prod.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "email",
            "openid"
        ],
        "redirectSignIn": "http://localhost:4200/loginsso/,https://dashboard-stg.cybeready.com/loginsso/,https://dashboard.cybeready.com/loginsso/,https://demo-stg.cybeready.com/loginsso/,https://demo.cybeready.com/loginsso/",
        "redirectSignOut": "http://localhost:4200/loginsso/,https://dashboard-stg.cybeready.com/loginsso/,https://dashboard.cybeready.com/loginsso/,https://demo-stg.cybeready.com/loginsso/,https://demo.cybeready.com/loginsso/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "training-materials-stg234933-prod",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
