import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './auth/login/login.component';
import { LoginssoComponent} from './auth/loginsso/loginsso.component';
import { AppGuard } from './guards/app.guard';
import { DesktopResolverService } from './guards/desktop-resolver.service';
import { TfaComponent } from './auth/tfa/tfa.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { SetPasswordComponent } from './auth/set-password/set-password.component';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';
import { SelectivePreloadingStrategyService } from './selective-preloading-strategy.service';
import { MobileComponent } from '@app/mobile/mobile.component';
import { MobileGuard } from '@app/guards/mobile.guard';
import {LoginGuard} from '@app/auth/login.guard';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard, MobileGuard],
  },
  {
    path: 'loginsso',
    component: LoginssoComponent,
    canActivate: [LoginGuard, MobileGuard],
  },
  {
    path: 'tfa',
    component: TfaComponent,
    canActivate: [MobileGuard],
  },
  {
    path: 'forgot-password',
    component: ResetPasswordComponent,
    canActivate: [MobileGuard],
  },
  {
    path: 'set-password/:token',
    component: SetPasswordComponent,
    canActivate: [MobileGuard],
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [AppGuard, MobileGuard],
  },
  {
    path: '',
    loadChildren: () => import('./desktop/desktop.module').then(m => m.DesktopModule),
    canActivate: [AppGuard, MobileGuard],
  },
  {
    path: 'mobile',
    component: MobileComponent,
  },
  { path: '**', redirectTo: '/report/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: SelectivePreloadingStrategyService
})],
  exports: [RouterModule],
  providers: [AppGuard, DesktopResolverService, MobileGuard]
})
export class AppRoutingModule {
}
