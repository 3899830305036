export const environment = {
  production: true,
  api: 'https://portal.cybeready.com/api',
  demoDomain: 'https://demo.cybeready.com',
  nonDemoDomain: 'https://dashboard.cybeready.com',
  gaCode: 'UA-61878315-2',
  authDomain: 'auth.cybeready.com',
  redirectSignInSuffix: 'loginsso/',
  redirectSignOutSuffix: 'loginsso/',
  localnonDemoDomain: '',
  froalaApiKey: 'aLF3c1A7D6C4F3D2G2C2xagte1kejB-7iA11liswaqtrnB3zuvg1G3I3A2B5D6B3C3E4D2F2==',
  heapApiKey: '385167711',
  churnZeroScriptUrl: 'https://cybeready.eu1app.churnzero.net/churnzero.js',
  churnZeroAppKey: '1!pjsUNgxM-J9UpvoFjqwCimodi-UEWSfuI9PVZJkwKQkt12F8'
};
